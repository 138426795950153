import { POSITIONS as type } from '@/store/types';
import Positions from '@/services/Api/Positions';

const state = {
  data: [],
  liaison: [],
  loading: false,
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.SET_LIAISON](state, data) {
    state.liaison = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {
  async fetchPositions({ commit }) {
    commit(type.LOADING, true);
    Positions.all()
      .then((response) => {
        const { status, data } = response.data;
        if (status === 200) {
          commit(type.SET, data);
        }
      })
      .finally(() => {
        commit(type.LOADING, false);
      });
  },
  async fetchPositionsForLiaison({ commit }) {
    commit(type.LOADING, true);
    Positions.liaison()
      .then((response) => {
        const { status, data } = response.data;
        if (status === 200) {
          commit(type.SET_LIAISON, data);
        }
      })
      .finally(() => {
        commit(type.LOADING, false);
      });
  },
};

const getters = {
  data(state) {
    return state.data;
  },
  assignableToCbLiaison(state) {
    return state.liaison.map((p) => p.id);
  },
  positionsNeedCoordinatingCommittee() {
    return state.data.filter((p) => {
      const names = [
        'Coordinating Committee Chair',
        'Coordinating Committee Vice-Chair',
      ];
      return names.includes(p.name);
    });
  },
  positionsNeedTechnicalCommittee() {
    return state.data.filter((p) => {
      const names = [
        'Technical Committee Chair',
        'Member',
      ];
      return names.includes(p.name);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
