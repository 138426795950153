import api from '@/services/Api';

class NationalMemberOrganizations {
  /**
   * Get all the address types
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/ref/national-member-organizations');
  }
}

export default NationalMemberOrganizations;
