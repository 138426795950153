import Vue from 'vue';
// Styles
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faArrowCircleRight,
  faFilter,
  faCheckCircle,
  faCircleXmark,
  faClock,
  faCircleEnvelope,
  faCircleExclamation,
  faArrowRotateRight,
  faLock,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowLeft,
  faMagnifyingGlass,
  faUserCircle,
  faCheck,
  faEur,
  faTimes,
  faCalendarAlt,
  faTrash,
  faComment,
  faTasks,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faChevronDown,
  faSearch,
  faUpload,
  faDownload,
} from '@fortawesome/pro-light-svg-icons';

// import {
// } from '@fortawesome/free-brands-svg-icons';

import './assets/styles/app.scss';

// External libs + Custom filters
import './services/external-libs';
import './services/directives';
import './services/validation';

// Dev
import Rollbar from 'rollbar';
import App from './App.vue';
import router from './router';
import store from './store';
import { keycloak, KeycloakAuthInit } from './services/auth';

Vue.use(store);
Vue.use(router);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

library.add(
  faArrowLeft,
  faFilter,
  faMagnifyingGlass,
  faUserCircle,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faEur,
  faTimes,
  faCalendarAlt,
  faComment,
  faTasks,
  faChevronDown,
  faSearch,
  faTrash,
  faUpload,
  faDownload,
  faArrowCircleRight,
  faCheckCircle,
  faCircleXmark,
  faClock,
  faCircleEnvelope,
  faCircleExclamation,
  faArrowRotateRight,
  faLock,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

// Set the Rollbar instance in the Vue prototype
// before creating the first Vue instance.
// This ensures it is available in the same way for every
// instance in your app.
Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.VUE_APP_ENVIRONMENT,
  enabled: process.env.VUE_APP_ENVIRONMENT !== 'development',
  person: {
    id: store.state.authed?.username,
  },
});

Vue.prototype.$keycloak = keycloak;
KeycloakAuthInit({
  router,
  store,
  authComplete: () => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  },
});
