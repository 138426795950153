import { AFFILIATES as type } from '@/store/types';
import Affiliates from '@/services/Api/Affiliates';

const state = {
  data: [],
  loading: false,
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {
  fetchAffiliates(
    { commit },
    {
      page,
      perPage,
      search,
      sortBy,
      direction,
      filter,
    },
  ) {
    commit(type.LOADING, true);

    Affiliates
      .get(
        page,
        perPage,
        search,
        sortBy,
        direction,
        filter,
      )
      .then(({ status, data } = {}) => {
        if (status === 200 && data?.data) {
          commit(type.SET, data);
        }
      })
      .finally(() => {
        commit(type.LOADING, false);
      });
  },
  fetchAffiliatesRequest(
    { commit },
    {
      page,
      perPage,
      search,
      sortBy,
      direction,
      filter,
    },
  ) {
    commit(type.LOADING, true);

    return Affiliates
      .get(
        page,
        perPage,
        search,
        sortBy,
        direction,
        filter,
      );
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
