import api from '@/services/Api';
import { cleanObject } from '@ifac/ui';

class Approvals {
  /**
   * Fetch all
   * @returns {AxiosPromise<any>}
   */
  static get(
    page = 1,
    perPage = 12,
    search = '',
    sortBy = null,
    direction = 'desc',
    filter = null,
  ) {
    page = parseInt(page, 10);
    perPage = parseInt(perPage, 10);

    // Clean up the empty fields being sent
    const params = cleanObject({
      page,
      perPage,
      sortBy,
      direction,
      search,
      filter,
    });

    return api.get('/approvals', {
      params,
    });
  }

  /**
   * Create a new approval
   * @returns {AxiosPromise<any>}
   */
  static create(payload) {
    return api.post('/approvals', payload);
  }

  /**
   * Delete an existing approval
   * @returns {AxiosPromise<any>}
   */
  static delete(id) {
    return api.delete(`/approvals/${id}`);
  }

  /**
   * Update a single Approval
   * @returns {AxiosPromise<any>}
   */
  static update(payload) {
    return api.put(`/approvals/${payload.id}`, payload);
  }

  /**
   * Submit a single Approval
   * @returns {AxiosPromise<any>}
   */
  static submit(id) {
    return api.post(`/approvals/${id}/submit`);
  }
}

export default Approvals;
