<template>
  <div id="app" class="d-flex flex-column">
    <ifac-notice-bar
      v-for="(n, i) in notifications"
      :key="i"
      class="notice-bar"
      :message="n.message"
      :link-text="n.link_text"
      :link-to="n.link_to"
      :is-warning="n.is_warning"
    ></ifac-notice-bar>
    <main-nav v-if="displayNav"></main-nav>
    <main
      v-if="!needsPortalContent"
      :class="displayNav && !mx_breadcrumb ? 'nav-space' : ''"
    >
      <div class="bread-crumbs d-flex align-items-center" v-if="mx_breadcrumb">
        <b-button class="crumb" variant="link" :to="mx_breadcrumbUrl">
          <font-awesome-icon
            class="mr-2"
            :icon="['far', 'arrow-left']"
          />{{ mx_breadcrumb.text }}
        </b-button>
      </div>
      <router-view />
    </main>
    <portal-content v-else></portal-content>
    <main-footer v-if="displayNav && !needsPortalContent"></main-footer>
    <cookie-law theme="ifac">
      <div slot="message" class="fw-bold">
        IFAC - the International Federation of Automatic Control - values the
        privacy of its members, affiliates and visitors to its website and is
        strongly committed to each visitor's right to privacy. By using IFAC's
        website, you express your acceptance of IFAC's privacy policy. For
        details of its privacy policy please follow
        <a href="https://www.ifac-control.org/privacy-policy" target="_blank"
          >this link.</a
        >
      </div>
    </cookie-law>
  </div>
</template>

<script>
import MainNav from '@/views/layouts/MainNav.vue';
import MainFooter from '@/views/layouts/MainFooter.vue';
import PortalContent from '@/views/layouts/PortalContent.vue';
import {
  IfacNoticeBar,
  ifacBreadcrumbMixin,
} from '@ifac/ui';
import CookieLaw from 'vue-cookie-law';
import '@/services/refData';
import { mapGetters } from 'vuex';

const pjson = require('../package.json');

export default {
  components: {
    CookieLaw,
    MainNav,
    MainFooter,
    PortalContent,
    IfacNoticeBar,
  },
  mixins: [ifacBreadcrumbMixin],
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    displayNav() {
      return !['PostRegister', 'ConferencesApply']
        .includes(this.$route.name);
    },
    needsPortalContent() {
      return ['ApprovalsEdit']
        .includes(this.$route.name);
    },
    editProfileUrl() {
      return '/account/edit-profile?section=general-details';
    },
    currentVersion() {
      return {
        app: pjson.version,
        ui: pjson.dependencies['@ifac/ui'],
      };
    },
    notifications() {
      if (!this.user || !this.displayNav) {
        return [];
      }

      return [
        {
          is_visible: this.user.isCompleted === false,
          message: 'Some information that may be useful for the IFAC secretariat is missing.',
          link_text: 'Complete profile',
          link_to: this.editProfileUrl,
          is_warning: false,
        },
        {
          is_visible: this.user.isApproved === false && this.user.isRejected === false,
          message: 'Your profile is pending approval by the secretariat.',
          link_text: null,
          link_to: null,
          is_warning: true,
        },
        {
          is_visible: this.user.isRejected === true,
          message: 'Your profile has been rejected. Please contact the IFAC secretariat for explanations. This could be due to duplicate profiles.',
          link_text: null,
          link_to: null,
          is_warning: true,
        },
        {
          is_visible: this.user.fullNameVisibility === 'private',
          message: 'Your profile is visible to IFAC secretariat only. Make your name ‘Web public’ or ‘IFAC public’ to appear among IFAC Affiliates.',
          link_text: null,
          link_to: null,
          is_warning: true,
        },
      ].filter((n) => !!n.is_visible);
    },
  },
  created() {
    console.info('Version', this.currentVersion);
  },
};
</script>

<style>
.Cookie--ifac {
  background: #253788;
  color: #fff;
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;

  padding: 1.25em;
}
.Cookie--ifac .Cookie__button {
  background: #fff;
  color: #000;
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  padding: 0.625em 3.125em;
  border-radius: 0;
  border: 0;
  font-size: 1em;
}
.Cookie--ifac div.Cookie__button:hover {
  color: #333 !important;
  font-weight: bold !important;
}

.Cookie--ifac a {
  color: #fff;
}

.nav-space {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
</style>
<style lang='scss'>
@import '@/assets/styles/base/_variables.scss';
@import '@/assets/styles/base/_mixins.scss';

.bread-crumbs {
  padding-left: 0.8rem;
  border-bottom:1px solid $color-grey-light;
  margin-bottom: 2rem;
  font-size: 0.875rem;

  .btn-link {
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0.75rem;
    color: inherit;
    &:focus {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
