import api from '@/services/Api';

class Addresses {
  /**
   * Get all the address types
   * @returns {AxiosPromise<any>}
   */
  static types() {
    return api.get('/ref/address-types');
  }
}

export default Addresses;
