import { CONFERENCE_REPORT as type } from '@/store/types';

const state = {
  data: [],
  loading: false,
  metas: [
    'meta_general',
    'meta_organization',
    'meta_participants',
    'meta_fees',
    'meta_authors',
    'meta_papers',
    'meta_budget',
    'meta_participation',
    'meta_publications',
    'meta_other',
    'meta_assessment',
    'meta_submission',
  ],
  uncompletedSections: [1, 2, 3, 4, 5, 6, 7, 8, 9],
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {};

const getters = {
  uncompletedSections(state) {
    return (report) => {
      if (report) {
        const uncompletedMetaKeys = state.metas.filter((k) => {
          // Only check the errors in case of the key exists.
          // in other case it has to be always true.
          if (report[k]) {
            return Object.values(report[k].errors).length > 0;
          }
          return true;
        });

        const uncompletedSections = [];
        uncompletedMetaKeys.forEach((k) => {
          if (k === 'meta_general' && !uncompletedSections.includes(1)) {
            uncompletedSections.push(1);
          } else if (k === 'meta_organization' && !uncompletedSections.includes(2)) {
            uncompletedSections.push(2);
          } else if (
            [
              'meta_fees',
              'meta_papers',
              'meta_authors',
              'meta_participants',
            ].includes(k)
            && !uncompletedSections.includes(3)
          ) {
            uncompletedSections.push(3);
          } else if (k === 'meta_budget' && !uncompletedSections.includes(4)) {
            uncompletedSections.push(4);
          } else if (k === 'meta_participation' && !uncompletedSections.includes(5)) {
            uncompletedSections.push(5);
          } else if (k === 'meta_publications' && !uncompletedSections.includes(6)) {
            uncompletedSections.push(6);
          } else if (k === 'meta_other' && !uncompletedSections.includes(7)) {
            uncompletedSections.push(7);
          } else if (k === 'meta_assessment' && !uncompletedSections.includes(8)) {
            uncompletedSections.push(8);
          } else if (k === 'meta_submission' && !uncompletedSections.includes(9)) {
            uncompletedSections.push(9);
          }
        });

        return uncompletedSections;
      }
      return state.uncompletedSections;
    };
  },
  lastSection(state, getters) {
    return (report, route) => {
      let uncompletedSection = 1;
      const currentSection = route.query?.section;
      if (currentSection) return Number(currentSection);
      if (report) {
        /* eslint-disable-next-line */
        for (const i of state.uncompletedSections) {
          if (getters.uncompletedSections(report).includes(i)) {
            uncompletedSection = i;
            break;
          }
        }
        return uncompletedSection;
      }
      return uncompletedSection;
    };
  },
  hasErrors() {
    return (report) => {
      if (report) {
        const metaKeys = Object.keys(report)
          .filter((k) => k.includes('meta'));

        const errors = metaKeys.filter((k) => {
          const errorObject = Object.values(report[k].errors)
            .filter((o) => o[0] && !o[0].includes('mandatory'));

          return errorObject.length > 0;
        });

        return errors.length > 0;
      }

      return false;
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
