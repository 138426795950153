// routes which require authentication
const authRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard-index" */ '../views/areas/dashboard/Index.vue'),
  },

  {
    path: '/conferences',
    name: 'Conferences',
    component: () => import(/* webpackChunkName: "conferences-index" */ '../views/areas/conferences/Index.vue'),
  },

  {
    path: '/conferences/apply',
    name: 'ConferencesApply',
    component: () => import(/* webpackChunkName: "conferences-apply" */ '../views/areas/conferences/Apply.vue'),
    meta: {
      breadcrumbParent: {
        path: '/conferences',
        text: 'My Applications',
      },
    },
  },

  {
    path: '/conferences/:id/reports/:reportId',
    name: 'ConferencesReport',
    component: () => import(/* webpackChunkName: "conferences-report" */ '../views/areas/reports/Edit.vue'),
    meta: {
      breadcrumbParent: {
        name: 'ConferencesCompleted',
        text: 'Conference - Summary',
      },
    },
  },

  {
    path: '/conferences/:id/reports/:reportId/details',
    name: 'ConferencesReportDetails',
    component: () => import(/* webpackChunkName: "conferences-report-details" */ '../views/areas/reports/Details.vue'),
    meta: {
      breadcrumbParent: {
        name: 'ConferencesCompleted',
        text: 'Conference - Summary',
      },
    },
  },

  {
    path: '/conferences/completed/:id',
    name: 'ConferencesCompleted',
    component: () => import(/* webpackChunkName: "conferences-apply" */ '../views/areas/conferences/Completed.vue'),
    meta: {
      breadcrumbParent: {
        path: '/conferences?section=historic',
        text: 'My Applications',
      },
    },
  },

  {
    path: '/approvals',
    name: 'Approvals',
    component: () => import(/* webpackChunkName: "approvals-index" */ '../views/areas/approvals/Index.vue'),
    meta: {
      breadcrumbParent: {
        path: '/dashboard',
        text: 'Dashboard',
      },
    },
  },

  {
    path: '/conferences/:conferenceId/approvals/:approvalId',
    name: 'ApprovalsEdit',
    component: () => import(/* webpackChunkName: "approvals-edit" */ '../views/areas/approvals/Edit.vue'),
    meta: {
      breadcrumbParent: {
        path: '/approvals',
        text: 'Approvals',
      },
    },
  },

  {
    path: '/account/edit-profile',
    name: 'ProfileEdit',
    component: () => import(/* webpackChunkName: "affiliates-edit" */ '../views/areas/account/EditProfile.vue'),
    meta: {
      breadcrumbParent: {
        path: '/dashboard',
        text: 'Dashboard',
      },
    },
  },

  {
    path: '/post-register',
    name: 'PostRegister',
    component: () => import(/* webpackChunkName: "post-register" */ '../views/areas/account/PostRegister.vue'),
  },
];

// routes which don't require authentication
const guestRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home-page" */ '../views/pages/Home.vue'),
  },

  {
    path: '/guest',
    name: 'Guest',
    component: () => import(/* webpackChunkName: "guest-page" */ '../views/pages/Guest.vue'),
  },

  {
    path: '/certificate',
    name: 'Certificate',
    component: () => import(/* webpackChunkName: "guest-page" */ '../views/pages/Certificate.vue'),
  },

  {
    path: '/affiliates',
    name: 'Affiliates',
    component: () => import(/* webpackChunkName: "affiliates-index" */ '../views/areas/affiliates/Index.vue'),
  },

  {
    path: '/affiliates/officials',
    name: 'IFAC Officials',
    component: () => import(/* webpackChunkName: "affiliates-index" */ '../views/areas/affiliates/Officials.vue'),
  },

  {
    path: '/affiliates/:id',
    name: 'AffiliatesView',
    component: () => import(/* webpackChunkName: "affiliates-view" */ '../views/areas/affiliates/View.vue'),
    meta: {
      breadcrumbParent: {
        name: 'Affiliates',
        text: 'Affiliates',
      },
    },
  },
];

export default ({ authGuard, guestGuard }) => [
  ...authGuard(authRoutes),
  ...guestGuard(guestRoutes),
];
