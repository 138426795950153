import api from '@/services/Api';

class Trienniums {
  /**
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/ref/trienniums');
  }
}

export default Trienniums;
