import { A_TYPES as type } from '@/store/types';
import Addresses from '@/services/Api/Addresses';

const state = {
  data: [],
  loading: false,
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {
  async fetchAddressTypes({ commit }) {
    commit(type.LOADING, true);
    Addresses.types()
      .then((response) => {
        const { status, data } = response.data;
        if (status === 200) {
          commit(type.SET, data);
        }
      })
      .finally(() => {
        commit(type.LOADING, false);
      });
  },
};

const getters = {
  data(state) {
    return state.data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
