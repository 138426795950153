import api from '@/services/Api';

class CareerStages {
  /**
   * Get all the career stages
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/ref/career-stages');
  }
}

export default CareerStages;
