import api from '@/services/Api';

class ConferenceTypes {
  /**
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/ref/conference-types');
  }
}

export default ConferenceTypes;
