/* eslint-disable import/prefer-default-export */

export const AUTH = Object.freeze({
  USER: {
    SET: 'AUTH_USER_SET',
  },
  REGISTER: 'AUTH_REGISTER',
  LOGIN: 'AUTH_LOGIN',
  LOGOUT: 'AUTH_LOGOUT',
  AUTHENTICATING: {
    SET: 'AUTH_AUTHENTICATING_SET',
  },
  AUTHENTICATED: {
    SET: 'AUTH_AUTHENTICATED_SET',
  },
  REDIRECT: {
    SET: 'AUTH_REDIRECT_SET',
  },
  KEYCLOAK: {
    TOKENS: {
      SET: 'AUTH_KEYCLOAK_TOKENS_SET',
    },
    PROFILE: {
      SET: 'AUTH_KEYCLOAK_PROFILE_SET',
    },
  },
});

export const CONFERENCES = Object.freeze({
  LOADING: 'CONFERENCES_LOADING',
  SET: 'CONFERENCES_SET',
  CLEAR: 'CONFERENCES_CLEAR',
});

export const AFFILIATES = Object.freeze({
  LOADING: 'AFFILIATES_LOADING',
  SET: 'AFFILIATES_SET',
  CLEAR: 'AFFILIATES_CLEAR',
});

export const COUNTRIES = Object.freeze({
  LOADING: 'COUNTRIES_LOADING',
  SET: 'COUNTRIES_SET',
  CLEAR: 'COUNTRIES_CLEAR',
});

export const GENDER = Object.freeze({
  LOADING: 'GENDERS_LOADING',
  SET: 'GENDERS_SET',
  CLEAR: 'GENDERS_CLEAR',
});

export const CORD_COMMITEES = Object.freeze({
  LOADING: 'COMMITEESS_LOADING',
  SET: 'COMMITEESS_SET',
  CLEAR: 'COMMITEES_CLEAR',
});

export const IAG = Object.freeze({
  LOADING: 'IAG_LOADING',
  SET: 'IAG_SET',
  CLEAR: 'IAG_CLEAR',
});

export const C_TYPES = Object.freeze({
  LOADING: 'C_TYPES_LOADING',
  SET: 'C_TYPES_SET',
  CLEAR: 'C_TYPES_CLEAR',
});

export const C_FORMATS = Object.freeze({
  LOADING: 'C_FORMATS_LOADING',
  SET: 'C_FORMATS_SET',
  CLEAR: 'C_FORMATS_CLEAR',
});

export const A_TYPES = Object.freeze({
  LOADING: 'A_TYPES_LOADING',
  SET: 'A_TYPES_SET',
  CLEAR: 'A_TYPES_CLEAR',
});

export const CONFERENCE_TYPES = Object.freeze({
  LOADING: 'CONFERENCE_TYPES_LOADING',
  SET: 'CONFERENCE_TYPES_SET',
  CLEAR: 'CONFERENCE_TYPES_CLEAR',
});

export const APPROVALS = Object.freeze({
  LOADING: 'APPROVALS_LOADING',
  SET: 'APPROVALS_SET',
  CLEAR: 'APPROVALS_CLEAR',
});

export const POSITIONS = Object.freeze({
  LOADING: 'POSITIONS_LOADING',
  SET: 'POSITIONS_SET',
  SET_LIAISON: 'SET_LIAISON',
  CLEAR: 'POSITIONS_CLEAR',
});

export const CAREER_STAGES = Object.freeze({
  LOADING: 'CAREER_STAGES_LOADING',
  SET: 'CAREER_STAGES_SET',
  CLEAR: 'CAREER_STAGES_CLEAR',
});

export const TRIENNIUMS = Object.freeze({
  LOADING: 'TRIENNIUMS_LOADING',
  SET: 'TRIENNIUMS_SET',
  CLEAR: 'TRIENNIUMS_CLEAR',
});

export const CONFERENCE_REPORT = Object.freeze({
  LOADING: 'CONFERENCE_REPORT_LOADING',
  SET: 'CONFERENCE_REPORT_SET',
  CLEAR: 'CONFERENCE_REPORT_CLEAR',
});

export const SETTINGS = Object.freeze({
  LOADING: 'SETTINGS_LOADING',
  SET: 'SETTINGS_SET',
  CLEAR: 'SETTINGS_CLEAR',
});

export const NMOS = Object.freeze({
  LOADING: 'NMOS_LOADING',
  SET: 'NMOS_SET',
  CLEAR: 'NMOS_CLEAR',
});

export const VENUE_TYPES = Object.freeze({
  LOADING: 'VENUE_TYPES_LOADING',
  SET: 'VENUE_TYPES_SET',
  CLEAR: 'VENUE_TYPES_CLEAR',
});
