import store from '@/store';

// Steup ref data
store.dispatch('coordinatingCommittees/fetchCoordinatingCommittees');
store.dispatch('countries/fetchCountries');
store.dispatch('addressTypes/fetchAddressTypes');
store.dispatch('genders/fetchGenders');
store.dispatch('careerStages/fetchCareerStages');
store.dispatch('industryAcademiaGovernment/fetchIndustryAcademiaGovernment');
store.dispatch('positions/fetchPositions');
store.dispatch('positions/fetchPositionsForLiaison');
store.dispatch('trienniums/fetchTrienniums');
store.dispatch('conferenceTypes/fetchConferenceTypes');
store.dispatch('venueTypes/fetchVenueTypes');
store.dispatch('nationalMemberOrganizations/fetchNMOs');
