var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-navbar"},[_c('div',{staticClass:"navbar-wrapper"},[_vm._m(0),_c('div',{staticClass:"nav-items-wrapper d-flex"},[_c('b-button',{staticClass:"nav-link mobile-hide",class:{
          active:
            this.$route.name === 'Dashboard' || this.$route.name === 'Home',
        },attrs:{"to":"/","variant":"link"}},[_vm._v(" Home ")]),_c('b-dropdown',{attrs:{"right":"","no-caret":"","variant":"link","toggle-class":"nav-link mobile-hide"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Affiliates ")]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"to":"/affiliates"}},[_vm._v("All Affiliates")]),_c('b-dropdown-item',{attrs:{"to":"/affiliates/officials"}},[_vm._v("IFAC Officials")])],1),(_vm.userIsApproved && _vm.conferencesEnabled)?_c('b-dropdown',{attrs:{"right":"","no-caret":"","variant":"link","toggle-class":['nav-link mobile-hide', _vm.conferencesActive]},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Conferences ")]},proxy:true}],null,false,2306712290)},[(_vm.isAuthenticated && _vm.hasApprovals && _vm.conferencesEnabled)?_c('b-dropdown-item',{attrs:{"to":"/approvals"}},[_vm._v(" My Approvals ")]):_vm._e(),_c('b-dropdown-item',{attrs:{"to":"/conferences"}},[_vm._v("My Applications")]),_c('b-dropdown-item',{attrs:{"href":"https://ifac.floq.live/","target":"_blank"}},[_vm._v("Go to Conference App")])],1):_vm._e(),(_vm.isAuthenticated)?_c('b-dropdown',{attrs:{"right":"","no-caret":"","variant":"link","toggle-class":"nav-link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" My account "),_c('font-awesome-icon',{attrs:{"icon":['far', 'circle-user']}})]},proxy:true}],null,false,1635245186)},[_c('b-dropdown-item',{on:{"click":_vm.editProfile}},[_vm._v("Edit Profile")]),_c('b-dropdown-item',{on:{"click":_vm.logout}},[_vm._v("Sign Out")])],1):_vm._e(),(!_vm.isAuthenticated)?_c('b-button',{staticClass:"nav-link",attrs:{"variant":"link"},on:{"click":_vm.login}},[_vm._v(" "+_vm._s(_vm.affiliatesRegistrationEnabled ? 'Login / Sign-Up' : 'Login')+" ")]):_vm._e()],1)]),_c('div',{staticClass:"mobile-nav-items-wrapper d-flex d-lg-none"},[_c('b-button',{staticClass:"nav-link",class:{
        active:
          this.$route.name === 'Dashboard' || this.$route.name === 'Home',
      },attrs:{"to":"/","variant":"link"}},[_vm._v(" Home ")]),_c('b-dropdown',{attrs:{"right":"","boundary":"viewport","no-caret":"","variant":"link","toggle-class":['nav-link', { active: this.$route.name === 'Affiliates' }]},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Affiliates ")]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"to":"/affiliates"}},[_vm._v("All Affiliates")]),_c('b-dropdown-item',{attrs:{"to":"/affiliates/officials"}},[_vm._v("IFAC Officials")])],1),(_vm.userIsApproved && _vm.conferencesEnabled)?_c('b-dropdown',{attrs:{"right":"","boundary":"viewport","no-caret":"","variant":"link","toggle-class":['nav-link', _vm.conferencesActive]},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Conferences ")]},proxy:true}],null,false,2306712290)},[(_vm.isAuthenticated && _vm.hasApprovals && _vm.conferencesEnabled)?_c('b-dropdown-item',{attrs:{"to":"/approvals"}},[_vm._v(" My Approvals ")]):_vm._e(),_c('b-dropdown-item',{attrs:{"to":"/conferences"}},[_vm._v("My Applications")]),_c('b-dropdown-item',{attrs:{"href":"https://ifac.floq.live/","target":"_blank"}},[_vm._v("Go to Conference App")])],1):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-wrapper"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"nav-logo d-none d-lg-block",attrs:{"src":require("@/assets/images/desktop-logo.svg"),"alt":"International Federation of Automatic Control"}}),_c('img',{staticClass:"nav-logo d-lg-none",attrs:{"src":require("@/assets/images/mobile-logo.svg"),"alt":"International Federation of Automatic Control"}})])])
}]

export { render, staticRenderFns }