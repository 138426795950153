import Keycloak from 'keycloak-js';

export const keycloak = Keycloak({
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
});

export async function KeycloakAuthInit(options) {
  const { store, router, authComplete } = options;

  keycloak.onAuthSuccess = async () => {
    await store.dispatch('auth/setKeycloakTokens', {
      token: keycloak.token,
      refreshToken: keycloak.refreshToken,
    });

    setInterval(() => keycloak.updateToken(30), 100000);

    try {
      // fetch keycloak profile
      store.dispatch('auth/setKeycloakProfile');

      // monitor authentication
      store.dispatch('auth/monitorAuthenticated');

      // // get url to redirect user to
      // const redirectTo = store.getters['auth/redirectRoute'];
      // if (redirectTo) {
      //   // clear down redirect link to prevent forwarding when pages
      //   // are manually refreshed in a web browser
      //   await store.dispatch('auth/redirectAfterAuth', null);

      //   // do redirect
      //   router.push({
      //     name: redirectTo,
      //   });
      // }
    } catch (e) {
      console.error('Keycloak initial error', e);
    }
  };

  keycloak.onAuthLogout = async () => {
    await store.dispatch('auth/resetUser');
    await store.dispatch('auth/setKeycloakTokens', {});

    // redirect user to home screen
    router.replace({
      name: 'Home',
    });
  };

  keycloak.onAuthRefreshSuccess = async () => {
    await store.dispatch('auth/setKeycloakTokens', {
      token: keycloak.token,
      refreshToken: keycloak.refreshToken,
    });
  };

  /*
  router.beforeEach((to, from, next) => {
    if (to.hash.match(/#state=/)) {
      return next({
        path: to.path,
        query: to.query,
        replace: true,
      });
    }

    return next();
  });
*/

  // update isAuthenticating status
  store.dispatch('auth/isAuthenticating', true);

  try {
    const keycloakInitConfig = {
      pkceMethod: 'S256',
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      enableLogging: true,
      checkLoginIframe: false,
    };
    await keycloak.init(keycloakInitConfig);

    // fetch user if we are authenticated - do this here rather than in onAuthSuccess()
    // to ensure data is loaded before app is created
    if (keycloak.authenticated) {
      await store.dispatch('auth/setUser');
    }
  } catch (e) {
    // ignore exception
  }

  // update isAuthenticating status
  store.dispatch('auth/isAuthenticating', false);

  // update isAuthenticated status
  store.dispatch('auth/setAuthenticated', keycloak.authenticated);

  authComplete();
}
