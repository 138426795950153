import { APPROVALS as type } from '@/store/types';
import Approvals from '@/services/Api/Approvals';

const state = {
  data: [],
  loading: false,
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {
  fetchApprovals(
    { commit },
    {
      page,
      perPage,
      search,
      sortBy,
      direction,
      filter,
    },
  ) {
    commit(type.LOADING, true);
    Approvals
      .get(
        page,
        perPage,
        search,
        sortBy,
        direction,
        filter,
      )
      .then(({ status, data } = {}) => {
        if (status === 200 && data?.data) {
          commit(type.SET, data);
        }
      })
      .finally(() => {
        commit(type.LOADING, false);
      });
  },
  /* eslint-disable no-unused-vars */
  createApproval(
    { commit },
    {
      treeId,
      affiliateId,
    },
  ) {
    return Approvals.create({
      treeId,
      affiliateId,
    });
  },
  /* eslint-disable no-unused-vars */
  deleteApproval(
    { commit },
    {
      approvalId,
    },
  ) {
    return Approvals.delete(approvalId);
  },
};

const getters = {
  hasApprovals(state) {
    const approvals = state.data?.data?.items;
    return approvals?.length > 0;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
