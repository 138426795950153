import { SETTINGS as type } from '@/store/types';
import Settings from '@/services/Api/Settings';

const state = {
  data: [],
  loading: false,
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {
  async fetchSettings({ commit }) {
    commit(type.LOADING, true);
    Settings.all()
      .then((response) => {
        const { status, data } = response?.data;
        if (status === 200) {
          commit(type.SET, data);
        }
      })
      .finally(() => {
        commit(type.LOADING, false);
      });
  },
  setSettings({ commit }, data) {
    commit(type.SET, data);
  },
};

const getters = {
  conferencesEnabled(state) {
    const conferencesEnabled = state.data.find((s) => s.key === 'conferencesEnabled');
    return !!conferencesEnabled?.value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
