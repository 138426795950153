<template>
  <div class="main-navbar">
    <div class="navbar-wrapper">
      <div class="logo-wrapper">
        <a href="/">
          <img
            src="@/assets/images/desktop-logo.svg"
            alt="International Federation of Automatic Control"
            class="nav-logo d-none d-lg-block"
          />
          <img
            src="@/assets/images/mobile-logo.svg"
            alt="International Federation of Automatic Control"
            class="nav-logo d-lg-none"
          />
        </a>
      </div>
      <div class="nav-items-wrapper d-flex">
        <b-button
          to="/"
          class="nav-link mobile-hide"
          variant="link"
          :class="{
            active:
              this.$route.name === 'Dashboard' || this.$route.name === 'Home',
          }"
        >
          Home
        </b-button>
        <b-dropdown right no-caret variant="link" toggle-class="nav-link mobile-hide">
          <template #button-content>
            Affiliates
          </template>
          <b-dropdown-item to="/affiliates">All Affiliates</b-dropdown-item>
          <b-dropdown-item to="/affiliates/officials">IFAC Officials</b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          right
          v-if="userIsApproved && conferencesEnabled"
          no-caret variant="link"
          :toggle-class="['nav-link mobile-hide', conferencesActive]">
          <template #button-content>
            Conferences
          </template>
          <b-dropdown-item to="/approvals"
                           v-if="isAuthenticated && hasApprovals && conferencesEnabled">
            My Approvals
          </b-dropdown-item>
          <b-dropdown-item to="/conferences">My Applications</b-dropdown-item>
          <b-dropdown-item href="https://ifac.floq.live/" target="_blank">Go to Conference App</b-dropdown-item>
        </b-dropdown>
        <b-dropdown right v-if="isAuthenticated" no-caret variant="link" toggle-class="nav-link">
          <template #button-content>
            My account <font-awesome-icon :icon="['far', 'circle-user']" />
          </template>
          <b-dropdown-item @click="editProfile">Edit Profile</b-dropdown-item>
          <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
        </b-dropdown>
        <b-button @click="login" class="nav-link" variant="link" v-if="!isAuthenticated">
          {{ affiliatesRegistrationEnabled ? 'Login / Sign-Up' : 'Login' }}
        </b-button>
      </div>
    </div>
    <div class="mobile-nav-items-wrapper d-flex d-lg-none">
      <b-button
        to="/"
        class="nav-link"
        variant="link"
        :class="{
          active:
            this.$route.name === 'Dashboard' || this.$route.name === 'Home',
        }"
      >
        Home
      </b-button>
      <b-dropdown
        right
        boundary="viewport"
        no-caret variant="link"
        :toggle-class="['nav-link', { active: this.$route.name === 'Affiliates' }]">
        <template #button-content>
          Affiliates
        </template>
        <b-dropdown-item to="/affiliates">All Affiliates</b-dropdown-item>
        <b-dropdown-item to="/affiliates/officials">IFAC Officials</b-dropdown-item>
      </b-dropdown>
      <b-dropdown
        right
        boundary="viewport"
        v-if="userIsApproved && conferencesEnabled"
        no-caret variant="link"
        :toggle-class="['nav-link', conferencesActive]">
        <template #button-content>
          Conferences
        </template>
        <b-dropdown-item to="/approvals"
                         v-if="isAuthenticated && hasApprovals && conferencesEnabled">
          My Approvals
        </b-dropdown-item>
        <b-dropdown-item to="/conferences">My Applications</b-dropdown-item>
        <b-dropdown-item href="https://ifac.floq.live/" target="_blank">Go to Conference App</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  created() {
    if (this.isAuthenticated) {
      this.fetchSettings();
      this.fetchApprovals({
        page: 1,
        perPage: 1,
      });
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      hasApprovals: 'approvals/hasApprovals',
      isAuthenticated: 'auth/isAuthenticated',
      conferencesEnabled: 'settings/conferencesEnabled',
    }),
    affiliatesRegistrationEnabled() {
      return process.env.VUE_APP_REGISTRATION_ENABLED === 'true';
    },
    userIsApproved() {
      return this.user?.isApproved;
    },
    conferencesActive() {
      return {
        active: ['Conferences', 'Approvals'].includes(this.$route.name),
      };
    },
  },
  methods: {
    ...mapActions({
      fetchSettings: 'settings/fetchSettings',
      fetchApprovals: 'approvals/fetchApprovals',
    }),
    editProfile() {
      this.$router.push({
        name: 'ProfileEdit',
        query: { section: 'general-details' },
        params: { id: this.user.id },
      });
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
    login() {
      this.$store.dispatch('auth/login');
    },
  },
};
</script>
<style lang='scss'>
@import "@/assets/styles/base/_variables.scss";
@import "@/assets/styles/base/_mixins.scss";

.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-nav-items-wrapper {
  display: flex;
  align-items: center;
  overflow: auto;
}

.nav-items-wrapper,
.mobile-nav-items-wrapper {
  .nav-link {
    font-size: 18px;
    padding: 0.75rem;
    color: $color-black-light;
    border-radius: 0;
    border: none;
    &:not(:last-child) {
      margin-right: 1.563rem;
    }
    &.active {
      color: $brand-dark-blue;
      border-bottom: $brand-dark-blue solid 0.2rem;
    }
    &.dropdown-toggle::after {
      display: none;
    }
    svg {
      margin-left: 0.75rem;
    }
    &:focus {
      box-shadow: none;
    }
    .btn {
      font-size: inherit;
      line-height: unset;
      padding: 0;
      color: inherit;
      &.btn-link {
        &:hover {
          text-decoration: none;
        }
      }
      &.dropdown-toggle {
        border: none;
      }
    }
    @include media(tablet) {
      &.mobile-hide {
        display: none;
      }
    }
  }
}
</style>

<style scoped lang='scss'>
@import "@/assets/styles/base/_variables.scss";
@import "@/assets/styles/base/_mixins.scss";

.navbar-wrapper {
  background-color: #ffffff;
  padding: 2.5rem 1.563rem 2.5rem 1.563rem;
  -moz-box-shadow: inset 0 -20px 20px -15px $color-grey-lighter;
  -webkit-box-shadow: inset 0 -20px 20px -15px $color-grey-lighter;
  box-shadow: inset 0 -20px 20px -15px $color-grey-lighter;

  .nav-logo {
    height: 7rem;
  }
  @include media(tablet) {
    padding: 0.75rem;
    .nav-logo {
      height: 4.625rem;
    }
  }
}
.mobile-nav-items-wrapper {
  background-color: #ffffff;
  padding: 0.75rem;
  border-top: 1px solid $color-grey-lighter;
  border-bottom: 1px solid $color-grey-lighter;
}
</style>
