import api from '@/services/Api';

class IndustryAcademiaGovernment {
  /**
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/ref/industry-academia-government');
  }
}

export default IndustryAcademiaGovernment;
