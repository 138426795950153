import api from '@/services/Api';

class Countries {
  /**
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/ref/countries');
  }

  static search(term) {
    return api.get(`/ref/countries?search=${term}`);
  }
}

export default Countries;
