<template>
  <div>
    <validation-provider :name="name" :rules="rules">
      <div slot-scope="{ errors }">
        <label v-if="title" :for="name">{{ title }}<span v-if="required"> *</span></label>
        <div class="row align-items-center">
          <div class="col-12">
            <input
              :id="name"
              type="text"
              v-model="formValue"
              class="form-control"
              :required="required"
              :placeholder="placeholderValue"
            />
            <p v-if="errors.length > 0" class="mb-0 mt-1 text-danger small">{{ errors[0] }}</p>
          </div>
          <ifac-info-icon-tooltip :tooltip="tooltip" />
        </div>
        <div class="row" v-if="formPrivacy">
          <div class="col-10 mt-3 mb-3">
            <ifac-privacy-selector
              v-model="formPrivacy"
            ></ifac-privacy-selector>
          </div>
        </div>
      </div>
    </validation-provider>
  </div>
</template>

<script>
import {
  IfacPrivacySelector,
  IfacInfoIconTooltip,
} from '@ifac/ui';

export default {
  components: {
    IfacPrivacySelector,
    IfacInfoIconTooltip,
  },
  props: {
    value: {
      type: String,
    },
    name: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: '',
    },
    privacy: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      formValue: this.value,
      formPrivacy: this.privacy,
    };
  },
  computed: {
    placeholderValue() {
      return this.placeholder || this.title;
    },
  },
  watch: {
    formValue: {
      handler(val) {
        this.$emit('input', val);
      },
    },
    formPrivacy: {
      handler(val) {
        this.$emit('privacy', val);
      },
    },
  },
};
</script>

<style>
</style>
