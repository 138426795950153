import api from '@/services/Api';

class Contacts {
  /**
   * Get all the contact info formats
   * @returns {AxiosPromise<any>}
   */
  static formats() {
    return api.get('/ref/contact-info-formats');
  }

  /**
   * Get all the contact info formats
   * @returns {AxiosPromise<any>}
   */
  static types() {
    return api.get('/ref/contact-info-types');
  }

  /**
   * Create a new contact-info related with a given affiliate id
   * @returns {AxiosPromise<any>}
   */
  static create(payload) {
    return api.post(`/affiliates/${payload.affiliateId}/contact-infos`, payload);
  }

  /**
   * Update an existing contact-info related with a given affiliate id
   * @returns {AxiosPromise<any>}
   */
  static update(payload) {
    return api.put(`/affiliates/${payload.affiliateId}/contact-infos/${payload.id}`, payload);
  }

  /**
   * Update an existing contact-info related with a given affiliate id
   * @returns {AxiosPromise<any>}
   */
  static delete(payload) {
    return api.delete(`/affiliates/${payload.affiliateId}/contact-infos/${payload.id}`, payload);
  }
}

export default Contacts;
