import Vue from 'vue';
import Vuex from 'vuex';
import { formHelpers, uiHelpers } from '@ifac/ui';

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/);
const modules = requireContext.keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((allModules, [name, module]) => {
    allModules[name] = module.default;
    return allModules;
  }, {});

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ...modules,
    uiHelpers,
    formHelpers,
  },
});
