import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import allRoutes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // eslint-disable-next-line consistent-return
  scrollBehavior(to, from) {
    // Skip scroll when opening and closing collapse cards from IFAC Officials page
    if (from.path === '/affiliates/officials' && to.path === '/affiliates/officials') {
      return false;
    }

    document.getElementById('app')
      .scrollIntoView({ behavior: 'smooth' });
  },
});

/**
 * Apply beforeEnter guard to the routes.
 *
 * @param  {Array} routes
 * @param  {Function} beforeEnter
 * @return {Array}
 */
function applyBeforeEnter(routes, beforeEnter) {
  return routes.map((route) => ({ ...route, beforeEnter }));
}

/**
 * Make sure user is logged in
 *
 * @param  {Array} routes
 * @return {Array}
 */
function authGuard(routes) {
  return applyBeforeEnter(routes, async (to, from, next) => {
    if (!store.getters['auth/isAuthenticated']) {
      next({
        name: 'Home',
        replace: true,
      });
    }

    // authenticated but registration not complete
    if (!store.getters['auth/isRegistrationComplete'] && to.name !== 'PostRegister') {
      next({
        name: 'PostRegister',
        replace: true,
      });
    }

    if (to.hash.match(/#state=/)) {
      next({ path: to.path, query: to.query, replace: true });
    }

    // affiliate not approved cannot access to Conference sections
    if (
      to.name.includes('Conference')
      && !store.getters['auth/user']?.isApproved
    ) {
      next({
        name: 'Home',
        replace: true,
      });
    }

    next();
  });
}

/**
 * Guest routes can be access by anyone
 *
 * @param  {Array} routes
 * @return {Array}
 */
function guestGuard(routes) {
  return applyBeforeEnter(routes, async (to, from, next) => {
    // authenticated but registration not complete
    if (store.getters['auth/isAuthenticated'] && !store.getters['auth/isRegistrationComplete']) {
      next({
        name: 'PostRegister',
        replace: true,
      });
    }

    if (to.hash.match(/#state=/)) {
      next({ path: to.path, query: to.query, replace: true });
    }

    next();
  });
}

allRoutes({ authGuard, guestGuard }).forEach((route) => {
  router.addRoute(route);
});

export default router;
