<template>
  <footer class="footer">
    <div
      class="container-fluid border-top border-secondary footer-container"
      style="border-width: 15px !important"
    >
      <b-row class="justify-content-center">
        <b-col sm="auto" class="footer-links">
          <ul class="list-group list-group-horizontal">
            <li class="list-group-item">
              <b-button
                size="sm"
                target="_blank"
                href="https://www.ifac-control.org/"
              >
                IFAC Control
                <i class="fas fa-external-link-alt"></i>
              </b-button>
            </li>
            <li class="list-group-item icon">
              <b-button
                size="sm"
                target="_blank"
                href="https://twitter.com/IFAC_Control">
                <span class="fa-stack" style="vertical-align: top;">
                  <i class="fal fa-circle fa-stack-2x"></i>
                  <i class="fab fa-twitter fa-stack-1x"></i>
                </span>
              </b-button>
            </li>
            <li class="list-group-item icon">
              <b-button
                size="sm"
                target="_blank"
                href="https://www.facebook.com/IFACcontrol">
                <span class="fa-stack" style="vertical-align: top;">
                  <i class="fal fa-circle fa-stack-2x"></i>
                  <i class="fab fa-facebook fa-stack-1x"></i>
                </span>
              </b-button>
            </li>
            <li class="list-group-item icon">
              <b-button
                size="sm"
                target="_blank"
                href="https://www.linkedin.com/groups/13400016">
                <span class="fa-stack" style="vertical-align: top;">
                  <i class="fal fa-circle fa-stack-2x"></i>
                  <i class="fab fa-linkedin fa-stack-1x"></i>
                </span>
              </b-button>
            </li>
            <li class="list-group-item icon">
              <b-button
                size="sm"
                target="_blank"
                href="https://www.youtube.com/channel/UCLcWoqbVNxo9rVSS9NKQDeA">
                <span class="fa-stack" style="vertical-align: top;">
                  <i class="fal fa-circle fa-stack-2x"></i>
                  <i class="fab fa-youtube fa-stack-1x"></i>
                </span>
              </b-button>
            </li>
          </ul>
        </b-col>
      </b-row>

      <b-row class="justify-content-center">
        <b-col sm="auto" md="6" class="footer-copy text-center">
          <p>
            <a href="https://www.ifac-control.org/contact/imprint"><u>Imprint</u></a>
            | © 2016 - {{ new Date().getFullYear() }} International Federation
            of Automatic Control. All Rights Reserved.
          </p>
          <p>
            IFAC - the International Federation of Automatic Control - values the privacy of its members, affiliates and visitors to its website and is strongly committed to each visitor's right to privacy. By using IFAC's website, you express your acceptance of IFAC's <a href="https://www.ifac-control.org/privacy-policy"><u>privacy policy</u></a>.
          </p>
        </b-col>
      </b-row>
    </div>
  </footer>
</template>

<script>
const pjson = require('../../../package.json');

export default {
  computed: {
    currentVersion() {
      const appVersion = pjson.version;
      const uiVersion = pjson.dependencies['@ifac/ui'];
      return `${appVersion} (UI: ${uiVersion})`;
    },
  },
};
</script>

<style scoped lang='scss'>
@import '@/assets/styles/base/_variables.scss';

.footer {
  background-color: #fff;
  .footer-container {
    padding: 1.5rem 1.563rem 0.5rem 1.563rem;
  }
  .footer-links {
    ul {
      li {
        &.list-group-item {
          padding: 0;
          border: none;
        }
        &.icon {
          margin-left: 0.75rem;
          a {
            color: $color-black-lighter;
            border: none;
          }
          .btn {
            width: 30px;
            margin-top: 0.188rem;
            padding: 0;
            border: none;
            &:active {
              background-color: transparent;
            }
            &:focus {
              background-color: transparent;
              box-shadow: none;
            }
          }
          .fa-circle {
            color: $color-grey-light;
          }
        }
      }
    }
  }
  .footer-copy {
    color: $color-grey-dark;
    font-size: 12px;
    a {
      color: $color-grey-dark;
    }
    p {
      margin: 1rem;
    }
  }
}

</style>
