import api from '@/services/Api';

class Conferences {
  /**
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/conferences');
  }

  /**
   * Get a single Conference
   * @returns {AxiosPromise<any>}
   */
  static get(id) {
    return api.get(`/conferences/${id}`);
  }

  /**
   * Create a single Conference
   * @returns {AxiosPromise<any>}
   */
  static update(payload) {
    return api.put(`/conferences/${payload.id}`, payload);
  }

  /**
   * Create a single Conference
   * @returns {AxiosPromise<any>}
   */
  static create(payload) {
    return api.post('/conferences', payload);
  }

  /**
   * Soft delete a single Conference
   * @returns {AxiosPromise<any>}
   */
  static delete(id) {
    return api.delete(`/conferences/${id}`);
  }

  /**
   * Create a single Conference Submission
   * @returns {AxiosPromise<any>}
   */
  static submit(payload) {
    return api.post(`/conferences/${payload.id}/submit`, payload);
  }

  /**
   * Create a conference file
   * @returns {AxiosPromise<any>}
   */
  static uploadFile(payload) {
    return api.post(`/conferences/${payload.id}/files`, payload);
  }

  /**
   * Fetch single
   * @returns {AxiosPromise<any>}
   */
  static approvalTree(id) {
    return api.get(`/conferences/${id}/approvals-tree`);
  }
}

export default Conferences;
