import Vue from 'vue';

import Vuelidate from 'vuelidate';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  required,
  email,
  min,
  max,
} from 'vee-validate/dist/rules';
import isURL from 'validator/lib/isURL';
import ValidInput from '@/views/components/inputs/ValidInput.vue';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('email', {
  ...email,
  message: 'Please provide a valid email address',
});

extend('min', {
  ...min,
  message(fieldName, rule) {
    return `Input not long enough, ${rule.length} characters required`;
  },
});

extend('max', {
  ...max,
  message(fieldName, rule) {
    return `Input is too long, ${rule.length} characters as maximum`;
  },
});

extend('url', {
  message() {
    return 'The URL is not valid eg. https://example.com';
  },
  validate(value) {
    return isURL(value, {
      require_tld: true,
    });
  },
});

Vue.use(Vuelidate);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('valid-input', ValidInput);
