import { C_TYPES as type } from '@/store/types';
import Contacts from '@/services/Api/Contacts';

const state = {
  data: [],
  loading: false,
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {
  async fetchContactTypes({ commit }) {
    commit(type.LOADING, true);
    Contacts
      .types()
      .then((response) => {
        const { status, data } = response.data;
        if (status === 200) {
          commit(type.SET, data);
        }
      })
      .finally(() => {
        commit(type.LOADING, false);
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
