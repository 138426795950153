import api from '@/services/Api';

class CoordinatingCommittees {
  /**
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/ref/coordinating-committees');
  }
}

export default CoordinatingCommittees;
