import api from '@/services/Api';

class Positions {
  /**
   * Get all the positions
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/ref/positions');
  }

  /**
   * Get all positions assignables to a CB Liaison
   * @returns {AxiosPromise<any>}
   */
  static liaison() {
    return api.get('/ref/positions/liaison');
  }
}

export default Positions;
