import { CONFERENCE_TYPES as type } from '@/store/types';
import ConferenceTypes from '@/services/Api/ConferenceTypes';

const state = {
  data: [],
  loading: false,
};

const mutations = {
  [type.LOADING](state, data) {
    state.loading = data;
  },
  [type.SET](state, data) {
    state.data = data;
  },
  [type.CLEAR]() {
    this.commit(type.SET, []);
  },
};

const actions = {
  async fetchConferenceTypes({ commit }) {
    commit(type.LOADING, true);
    ConferenceTypes.all()
      .then((response) => {
        const { status, data } = response.data;
        if (status === 200) {
          commit(type.SET, data);
        }
      })
      .finally(() => {
        commit(type.LOADING, false);
      });
  },
};

const getters = {
  data(state) {
    return state.data;
  },
  mainSponsoredTypes(state) {
    return state.data
      .filter((t) => t.id !== 'other')
      .map((o) => {
        if (o.id === 'congress') {
          return {
            id: o.id,
            name: 'World Congress',
          };
        }
        return o;
      });
  },
  coSponsoredTypes(state) {
    return state.data.filter((t) => t.id !== 'symposium' && t.id !== 'congress');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
