import api from '@/services/Api';

class Genders {
  /**
   * @returns {AxiosPromise<any>}
   */
  static all() {
    return api.get('/ref/genders');
  }

  static search(term) {
    return api.get(`/ref/genders?search=${term}`);
  }
}

export default Genders;
